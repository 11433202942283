export const Get5YearsArticleContent = {
  status: 200,
  items: [
    {
      id: 167,
      meta: {
        type: 'rewardshop.FlexiblePage',
        detail_url: 'https:///api/v2/drafts/167/',
        html_url: 'http://localhost/5-years-iqos-club/',
        slug: '5-years-iqos-club',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-10-02T16:37:27.459927+02:00',
        alias_of: null,
        parent: {
          id: 3,
          meta: {
            type: 'home.HomePage',
            detail_url: 'https:///api/v2/drafts/3/',
            html_url: 'http://localhost/',
          },
          title: 'Home',
        },
        locale: 'en',
      },
      title: '5-years-iqos-club',
      page_content: [
        {
          type: 'strings',
          value: {
            obj_id: 'main-heading-phase-1',
            content:
              '<p data-block-key="9jurk">5 Jahre IQOS Club loading …</p><p data-block-key="cqqtn">Bald geht&amp;apos;s los:</p>',
          },
          id: 'bc49d18c-171e-498f-a4d1-0fe27847ea1d',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'reminder-button-phase-1-text',
            content: '<p data-block-key="9jurk">Erinnere mich</p>',
          },
          id: '3bc9befd-86d3-4361-a99f-151eb145d584',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'reminder-button-phase-1-ics',
            content:
              '<p data-block-key="9jurk"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/5_Jahre_IQOS_CLUB._Save_the_date.ics">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/5_Jahre_IQOS_CLUB._Save_the_date.ics</a></p>',
          },
          id: '5e023acd-5f6d-4f9e-b29a-768b5117367e',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'reminder-button-phase-1-action',
            content:
              '<p data-block-key="9jurk">Teaser_5Jahre_SaveTheDate_Reminder</p>',
          },
          id: '9cc5ff74-5252-4e2f-a7b0-9ed415a4487e',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'countdown-target',
            content: '<p data-block-key="9jurk">2024-10-14T11:00:00</p>',
          },
          id: '66304e67-ea28-49af-a44c-4d10fcd25bed',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'game-playable-url',
            content:
              '<p data-block-key="9jurk"><a href="https://philip-morris-germany.campaign.playable.com/iqos-5yr-mini-mission">https://philip-morris-germany.campaign.playable.com/iqos-5yr-mini-mission</a></p>',
          },
          id: 'fbd28161-f61c-4a92-ac1c-036e40f425db',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'main-heading-phase-2',
            content:
              '<p data-block-key="9jurk">Jetzt Feld aufdecken und gewinnen.</p>',
          },
          id: 'f16183b6-e042-4c73-abb9-035057b8912d',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'reminder-button-phase-2-text',
            content: '<p data-block-key="9jurk">Kein Highlight verpassen</p>',
          },
          id: 'f0f2ce2b-ef05-4735-baf7-610b6b2224f0',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'reminder-button-phase-2-ics',
            content:
              '<p data-block-key="9jurk"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Das_nachste_Highlight_wartet.ics">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Das_nachste_Highlight_wartet.ics</a></p>',
          },
          id: '4d66a2f3-b092-476f-9e1d-71c19b672ff3',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'reminder-button-phase-2-action',
            content:
              '<p data-block-key="9jurk">MiniMission_5Jahre_Glücksrad_Reminder</p>',
          },
          id: 'aac1731f-ebef-4a71-8e1c-f4e6f6ba8b57',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'over-heading-1',
            content:
              '<p data-block-key="9jurk">10 Jahre IQOS, 5 Jahre IQOS CLUB. Wenn das kein Grund zu feiern ist!</p>',
          },
          id: 'bd6440b0-c010-45da-b44f-db178ddacdb6',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'over-heading-2',
            content:
              '<p data-block-key="9jurk">Unsere Überraschung für Euch:</p><p data-block-key="6480v"><b>50.000 € in Form von 500.000 IQOS Points!</b></p>',
          },
          id: '874c5d47-5bba-4002-ab37-85680e2c32fa',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'sub-heading-1',
            content: '<p data-block-key="9jurk">So einfach geht’s:</p>',
          },
          id: '5e85f425-7e47-4482-8cb4-b40c3fb5a69a',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'paragraph-1',
            content:
              '<p data-block-key="9jurk">Komme vom <b>14.10. bis 18.10.</b> jeden Tag in den Club, decke ein Feld auf und lass Dich auf eine spannende Reise durch die letzten 5 Jahre mitnehmen. Drehe täglich bis zu 5x am Glücksrad und gewinne Tag für Tag IQOS Points.&amp;sup1;</p>',
          },
          id: '50a5877a-bc8d-4684-a693-3b35c552d4d1',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'sub-heading-2',
            content:
              '<p data-block-key="9jurk">Mach Dich bereit für weitere Überraschungen:</p>',
          },
          id: '8723eea8-bab9-4feb-b67a-47f63274989a',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'section-1-text',
            content:
              '<p data-block-key="9jurk">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>',
          },
          id: '3f048bbf-4948-435e-a1bd-661e9c137a54',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'section-1-button-text',
            content: '<p data-block-key="9jurk">Erinnere mich</p>',
          },
          id: '65954f72-0c11-4269-ba16-d9008294f624',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'section-1-button-ics',
            content:
              '<p data-block-key="9jurk"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Jetzt_Punkte_einlosen.ics">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Jetzt_Punkte_einlosen.ics</a></p>',
          },
          id: '3986f23d-baa5-4070-ac7a-1a370b5cc202',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'section-1-button-action',
            content: '<p data-block-key="9jurk">Reminder_5Jahre_Highlight2</p>',
          },
          id: '806383d9-f691-47e1-b7cc-c769706f3c9c',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'section-2-text',
            content:
              '<p data-block-key="9jurk">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>',
          },
          id: '148bc270-b70f-4249-b305-021c7f6dfb25',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'section-2-button-text',
            content: '<p data-block-key="9jurk">Erinnere mich</p>',
          },
          id: '57c25834-1cd8-45de-99c1-290fcdf755c3',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'section-2-button-ics',
            content:
              '<p data-block-key="9jurk"><a href="https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Jetzt_Feedback_geben.ics">https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/Jetzt_Feedback_geben.ics</a></p>',
          },
          id: '1ef8e718-1776-4637-821c-ad3d386b194f',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'section-2-button-action',
            content: '<p data-block-key="9jurk">Reminder_5Jahre_Highlight3</p>',
          },
          id: '3a3b4efc-4882-439c-a82b-75e63f67da8f',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'main-heading-phase-3',
            content:
              '<p data-block-key="cki7w">Löse Deine IQOS Points gegen limitierte Prämien ein!</p>',
          },
          id: 'c0d42547-d102-4f4f-bbd7-30cba94499ed',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-redeem-button-text',
            content: '<p data-block-key="cki7w">Jetzt einlösen</p>',
          },
          id: 'f2dc0034-bef9-4135-ac16-7a652835cf26',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-reminder-button-text',
            content: '<p data-block-key="cki7w">Erinnerung setzen</p>',
          },
          id: '4e9d4c3c-75bb-43d0-a30b-eea322986865',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-kalender-link',
            content:
              '<p data-block-key="cki7w"><a href="https://de.iqos.com/rewardshop/product/66f56d1f22f3b65c441388c8/26c738d2-7c12-11ef-a003-0242c0a84005">https://de.iqos.com/rewardshop/product/66f56d1f22f3b65c441388c8/26c738d2-7c12-11ef-a003-0242c0a84005</a></p>',
          },
          id: 'a9078bc7-f1b3-4c51-a93f-6a4d3005ad62',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-kalender-heading',
            content: '<p data-block-key="cki7w">Ab dem 21.10.:</p>',
          },
          id: 'df55b9bb-8e2d-45b5-99cb-e4a4632a09ba',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-kalender-sub-heading',
            content: '<p data-block-key="cki7w">Birthday-Kalender</p>',
          },
          id: '1d6db545-8fa8-4626-9fec-768946b12d91',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-paragraph-1',
            content:
              '<p data-block-key="cki7w">Den <b>Birthday Kalender gibt es 5 Tage lang</b>. Auch hier musst Du schnell sein, denn der Kalender ist ebenfalls streng limitiert.</p>',
          },
          id: 'fc2ce14f-879c-4910-80e9-38260fd9946b',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-paragraph-2-1',
            content:
              '<p data-block-key="cki7w">10 Jahre IQOS, 5 Jahre IQOS CLUB.</p><p data-block-key="4vt7i">Wenn das kein Grund zu feiern ist!</p>',
          },
          id: '32e1316d-1c7a-401d-be5e-5d02e07a6f63',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-paragraph-2-2',
            content:
              '<p data-block-key="cki7w">Unsere <b>2. Überraschung</b> für Euch:</p><p data-block-key="8hv3q"><b>5 Tage. 5 streng limitierte Partnerprämien.</b></p>',
          },
          id: '3dc559e3-5013-4db2-96b7-601ab090613b',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-paragraph-2-3',
            content:
              '<p data-block-key="cki7w">Freue Dich 5 Tage lang <b>jeden Tag auf ein neues Highlight</b> im Prämienshop.</p>',
          },
          id: '4345b0e5-d5b2-47a2-97a7-16d06657e876',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-paragraph-3-heading',
            content: '<p data-block-key="cki7w">So einfach geht’s:</p>',
          },
          id: 'fba93ddb-7a02-41a8-acaf-68aed7f329df',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-paragraph-3',
            content:
              '<p data-block-key="cki7w">Komme vom <b>21.10. bis 25.10.</b> jeden Tag um 17 Uhr nachmittags in den Club und löse Deine IQOS Points gegen unsere exklusiven, limitierten Prämien ein!</p><p data-block-key="1ro7g"></p><p data-block-key="3u3uk">Wenn Du die <b>Erinnerungs-Funktion</b> nutzt, wirst Du <b>täglich</b> an das jeweils neue Highlight im Prämienshop erinnert.</p>',
          },
          id: 'c6b98f2a-fb76-4dce-8d40-d2b0644581c6',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-3-bottom-heading',
            content:
              '<p data-block-key="cki7w">Mach Dich bereit für Deine 3. Überraschung:</p>',
          },
          id: '4b165bd3-4c06-4ff5-b3d9-3292a52d376c',
        },
        {
          type: 'image',
          value: {
            obj_id: 'carousel-present-2',
            image: {
              id: 446,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/dinner-gutschein-unlocked_stoerer.jpg',
              title: 'dinner-gutschein-unlocked_stoerer',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/dinner-gutschein-unlocked_stoerer.scale-50.jpg',
                width: 800,
                height: 800,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/dinner-gutschein-unlocked_stoerer.scale-100.jpg',
                width: 1600,
                height: 1600,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/dinner-gutschein-unlocked_stoerer.scale-15.jpg',
                width: 240,
                height: 240,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/dinner-gutschein-unlocked_stoerer.scale-25.jpg',
                width: 400,
                height: 400,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/dinner-gutschein-unlocked_stoerer.scale-35.jpg',
                width: 560,
                height: 560,
              },
            },
            header: '',
            content:
              '<p data-block-key="0jktn">75 € Dinner-Gutschein</p><hr/><p data-block-key="a21su">22.10.</p><hr/><p data-block-key="820nf">unlocked</p><hr/><p data-block-key="qq51">/5years/reminder_present-2.ics</p><hr/><p data-block-key="ckhvf"><a href="https://de.iqos.com/rewardshop/product/670f91c8f7e898f9ffacea48/81f08330-8ba8-11ef-bff0-0242ac1b0005">https://de.iqos.com/rewardshop/product/670f91c8f7e898f9ffacea48/81f08330-8ba8-11ef-bff0-0242ac1b0005</a></p><hr/><p data-block-key="2u7k1">5YA_Present_Reminder_BONBON_Voucher</p>',
          },
          id: '72eeee89-785d-47c8-bda2-bfb6694ae745',
        },
        {
          type: 'image',
          value: {
            obj_id: 'carousel-present-3',
            image: {
              id: 438,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/airpods-unlocked.png',
              title: 'airpods-unlocked',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airpods-unlocked.scale-50.png',
                width: 800,
                height: 800,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airpods-unlocked.scale-100.png',
                width: 1600,
                height: 1600,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airpods-unlocked.scale-15.png',
                width: 240,
                height: 240,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airpods-unlocked.scale-25.png',
                width: 400,
                height: 400,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airpods-unlocked.scale-35.png',
                width: 560,
                height: 560,
              },
            },
            header: '',
            content:
              '<p data-block-key="0jktn">Apple AirPods 3. Generation</p><hr/><p data-block-key="4p9lm">23.10.</p><hr/><p data-block-key="bk3bs">locked</p><hr/><p data-block-key="9kg4t">/5years/reminder_present-3.ics</p><hr/><p data-block-key="e5sav"><a href="https://de.iqos.com/rewardshop/product/66f6a49caa21aa843f7523db/cfeea170-7ccb-11ef-9d41-0242c0a86005">https://de.iqos.com/rewardshop/product/66f6a49caa21aa843f7523db/cfeea170-7ccb-11ef-9d41-0242c0a86005</a></p><hr/><p data-block-key="8tu7h">5YA_Present_Reminder_AirPods</p>',
          },
          id: 'd29b98c4-2cb9-4165-9b69-0acb5385eecd',
        },
        {
          type: 'image',
          value: {
            obj_id: 'carousel-present-4',
            image: {
              id: 439,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/airtag-unlocked.png',
              title: 'airtag-unlocked',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airtag-unlocked.scale-50.png',
                width: 800,
                height: 800,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airtag-unlocked.scale-100.png',
                width: 1600,
                height: 1600,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airtag-unlocked.scale-15.png',
                width: 240,
                height: 240,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airtag-unlocked.scale-25.png',
                width: 400,
                height: 400,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/airtag-unlocked.scale-35.png',
                width: 560,
                height: 560,
              },
            },
            header: '',
            content:
              '<p data-block-key="0jktn">Apple AirTag</p><hr/><p data-block-key="2pjgp">24.10.</p><hr/><p data-block-key="ar4sv">locked</p><hr/><p data-block-key="1vmeo">/5years/reminder_present-4.ics</p><hr/><p data-block-key="4tekj"><a href="https://de.iqos.com/rewardshop/product/66f69bafc38ea54672751aac/7e184068-7cc6-11ef-807b-0242c0a86005">https://de.iqos.com/rewardshop/product/66f69bafc38ea54672751aac/7e184068-7cc6-11ef-807b-0242c0a86005</a></p><hr/><p data-block-key="26rta">5YA_Present_Reminder_AirTag</p>',
          },
          id: 'e46318c3-f53f-41ee-a47e-f574da5676d8',
        },
        {
          type: 'image',
          value: {
            obj_id: 'carousel-present-5',
            image: {
              id: 440,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/sonos-unlocked.png',
              title: 'sonos-unlocked',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/sonos-unlocked.scale-50.png',
                width: 800,
                height: 800,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/sonos-unlocked.scale-100.png',
                width: 1600,
                height: 1600,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/sonos-unlocked.scale-15.png',
                width: 240,
                height: 240,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/sonos-unlocked.scale-25.png',
                width: 400,
                height: 400,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/sonos-unlocked.scale-35.png',
                width: 560,
                height: 560,
              },
            },
            header: '',
            content:
              '<p data-block-key="0jktn">Sonos Roam 2</p><hr/><p data-block-key="5vtb5">25.10.</p><hr/><p data-block-key="7ee7k">locked</p><hr/><p data-block-key="d22n5">/5years/reminder_present-5.ics</p><hr/><p data-block-key="9m1d3"><a href="https://de.iqos.com/rewardshop/product/66d1925129ebe4a7edba0c8d/2890f032-66b3-11ef-a54e-0242ac120005">https://de.iqos.com/rewardshop/product/66d1925129ebe4a7edba0c8d/2890f032-66b3-11ef-a54e-0242ac120005</a></p><hr/><p data-block-key="3b61v">5YA_Present_Reminder_SONOS</p>',
          },
          id: '9863701b-c400-4a69-8f62-315b821e68b4',
        },
        {
          type: 'image',
          value: {
            obj_id: 'carousel-present-1',
            image: {
              id: 445,
              type: 'image',
              url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/original_images/reisegutschein-unlocked_stoerer.jpg',
              title: 'reisegutschein-unlocked_stoerer',
              image_desktop_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/reisegutschein-unlocked_stoerer.scale-50.jpg',
                width: 800,
                height: 800,
              },
              image_desktop_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/reisegutschein-unlocked_stoerer.scale-100.jpg',
                width: 1600,
                height: 1600,
              },
              image_mobile_1x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/reisegutschein-unlocked_stoerer.scale-15.jpg',
                width: 240,
                height: 240,
              },
              image_mobile_2x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/reisegutschein-unlocked_stoerer.scale-25.jpg',
                width: 400,
                height: 400,
              },
              image_mobile_3x: {
                url: 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/images/reisegutschein-unlocked_stoerer.scale-35.jpg',
                width: 560,
                height: 560,
              },
            },
            header: '',
            content:
              '<p data-block-key="0jktn">50 € Reise-Gutschein</p><hr/><p data-block-key="2e8cm">21.10.</p><hr/><p data-block-key="5r9mb">unlocked</p><hr/><p data-block-key="6lkm7">/5years/reminder_present-1.ics</p><hr/><p data-block-key="9skfp"><a href="https://de.iqos.com/rewardshop/product/670e46a317789ff678695445/08bb7798-8ae2-11ef-b7d9-0242ac1a0005">https://de.iqos.com/rewardshop/product/670e46a317789ff678695445/08bb7798-8ae2-11ef-b7d9-0242ac1a0005</a></p><hr/><p data-block-key="4pttf">5YA_Present_Reminder_Travel_Voucher</p>',
          },
          id: '6f081df7-b122-4659-9a17-25341c865035',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'hidden-product-name',
            content: '<p data-block-key="pe1jl">Neugierig?</p>',
          },
          id: '3e2ddf87-7477-4858-9816-ce37535138a2',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-points',
            content: '<p data-block-key="pe1jl">150 IQOS Points</p>',
          },
          id: '93df1d4b-8df5-43ef-815d-c4c883d5ed21',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-heading',
            content:
              '<p data-block-key="pe1jl">Deine &lt;u&gt;Meinung&lt;/u&gt; zählt!</p>',
          },
          id: '971e05c1-602c-4c28-a92b-a72285349472',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-paragraph-1',
            content:
              '<p data-block-key="pe1jl">Nun bist Du gefragt: <b>Wie soll die Zukunft des IQOS CLUB aussehen?</b> Was wünschst Du Dir? Was kann noch besser werden? Sag uns <b>Deine Meinung</b> und erhalte dafür <b>15 € in Form von 150 IQOS Points.</b></p>',
          },
          id: '9a6d4d7b-fa4f-4ac8-a96d-2db0ada82d2b',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-paragraph-2',
            content:
              '<p data-block-key="7psez">Du wurdest noch nicht per E-Mail von uns kontaktiert und willst an der Umfrage teilnehmen? Dann registriere Dich jetzt ganz einfach. Du erhältst am 06.11. eine E-Mail von uns.</p>',
          },
          id: '68e06d31-7a25-44c8-89c9-b2c868441d8e',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-survey-button-text',
            content:
              '<p data-block-key="pe1jl">Ich will die Zukunft mitgestalten!</p>',
          },
          id: 'c6f82678-bfc9-4a2f-8bb9-8dc577d05a63',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-survey-button-action',
            content:
              '<p data-block-key="pe1jl">5YA_Zukunft_mitgestalten_Button_Click</p>',
          },
          id: 'e08c1c16-9deb-4b1a-8f69-30a521c34978',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-reminder-heading',
            content:
              '<p data-block-key="pe1jl">Und das Club-Jahr ist noch lange nicht zu Ende. Viele weitere Highlights warten auf Dich.</p>',
          },
          id: '2b0a9385-5e1a-4498-9e14-c8658bd2a19b',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-reminder-paragraph',
            content:
              '<p data-block-key="pe1jl">Wenn Du <b>kein Highlight verpassen willst</b>, update jetzt ganz einfach Deine Kommunikationseinstellungen in Deinem Profil unter <b>Meine Details</b>.</p>',
          },
          id: '4f996011-fb66-47a2-89aa-525789977a62',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-settings-button-text',
            content: '<p data-block-key="pe1jl">Hier entlang</p>',
          },
          id: '8cbd3ef4-918d-4cce-a583-43cd8ad3618d',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'phase-4-settings-button-link',
            content:
              '<p data-block-key="pe1jl"><a href="https://www.iqos.com/de/de/mein-profil.html#">https://www.iqos.com/de/de/mein-profil.html#</a></p>',
          },
          id: '188c5af8-123b-4ee5-a287-758e1d49226d',
        },
        {
          type: 'strings',
          value: {
            obj_id: 'tnc-link',
            content:
              '<p data-block-key="9jurk">/teilnahmebedingungen/5-years-iqos-club</p>',
          },
          id: '56ce3a50-af96-409f-8ede-c3b047e89394',
        },
      ],
    },
  ],
};
