import { ConsumerLoyaltyDTO, Response } from '../dtos';
import { ConsumerLoyalty, LoyaltyErrorResponse } from '../../types';

import { loyaltyNormalizer } from './consumer-loyalty';

const normalizeConsumer = (response: ConsumerLoyaltyDTO): ConsumerLoyalty => ({
  consumer: {
    id: response.consumer_id,
    ...response.consumer,
  },
  loyalty: loyaltyNormalizer(response.loyalty),
  isClubUser: true,
});

const USER_NOT_FROM_CLUB_ERROR_MESSAGE = 'Loyalty Program is not Active';

const normalizeErrorResponse = ({
  error_message: errorMessage,
  status,
}: Response): LoyaltyErrorResponse =>
  status === 406 && errorMessage === USER_NOT_FROM_CLUB_ERROR_MESSAGE
    ? { errorMessage, isClubUser: false }
    : { errorMessage, isClubUser: true };

export default (
  consumerResponse: ConsumerLoyaltyDTO
): ConsumerLoyalty | LoyaltyErrorResponse =>
  'error_message' in consumerResponse
    ? normalizeErrorResponse(consumerResponse)
    : normalizeConsumer(consumerResponse);
