export default {
  status: 200,
  items: [
    {
      id: 220,
      meta: {
        type: 'rewardshop.HeroSliderEntry',
        detail_url: 'https:///api/v2/drafts/220/',
        html_url:
          'https://localhost/rewardshop-cms2/hero-slider/5-jahre-iqos-club-500000-iqos-points-fur-euch/',
        slug: '5-jahre-iqos-club-500000-iqos-points-fur-euch',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-10-08T16:04:00.221119+02:00',
        alias_of: null,
        parent: {
          id: 142,
          meta: {
            type: 'rewardshop.HeroSlider',
            detail_url: 'https:///api/v2/drafts/142/',
            html_url: 'https://localhost/rewardshop-cms2/hero-slider/',
          },
          title: 'Hero Slider',
        },
        locale: 'en',
      },
      title: '5 Jahre IQOS CLUB. \u2028500.000 IQOS Points f\u00fcr Euch.',
      theme: 'light',
      headline:
        'Drehe vom 14. \u2013 18.10. t\u00e4glich an unserem Gl\u00fccksrad. Es warten insgesamt 50.000 \u20ac in Form von IQOS Points auf Euch.',
      content:
        '<p data-block-key="7dzeh">Drehe vom 14. \u2013 18.10. t\u00e4glich an unserem Gl\u00fccksrad. Es warten insgesamt 50.000 \u20ac in Form von IQOS Points auf Euch.</p>',
      cta_name: 'Jetzt mitmachen',
      cta_link: 'https://de.iqosclub.com/5year-anniversary',
      image: {
        id: 589,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_5YA-Desktop_clean.png',
        title: 'IQOS_5YA-Desktop_clean',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Desktop_clean.scale-50.png',
          width: 1608,
          height: 564,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Desktop_clean.scale-100.png',
          width: 3216,
          height: 1128,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Desktop_clean.2e16d0ba.fill-4320x2316.png',
          width: 2106,
          height: 1128,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Desktop_clean.2e16d0ba.fill-415x375.png',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Desktop_clean.2e16d0ba.fill-830x750.png',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Desktop_clean.2e16d0ba.fill-1245x1125.png',
          width: 1245,
          height: 1125,
        },
      },
      image_mobile: {
        id: 590,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_5YA-Mobile_clean.png',
        title: 'IQOS_5YA-Mobile_clean',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Mobile_clean.2e16d0ba.fill-1440x772.png',
          width: 1280,
          height: 688,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Mobile_clean.2e16d0ba.fill-2880x1544.png',
          width: 1280,
          height: 688,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Mobile_clean.2e16d0ba.fill-4320x2316.png',
          width: 1280,
          height: 688,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Mobile_clean.scale-15.png',
          width: 192,
          height: 201,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Mobile_clean.scale-25.png',
          width: 320,
          height: 336,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_5YA-Mobile_clean.scale-35.png',
          width: 448,
          height: 470,
        },
      },
    },
    {
      id: 213,
      meta: {
        type: 'rewardshop.HeroSliderEntry',
        detail_url: 'https:///api/v2/drafts/213/',
        html_url:
          'https://localhost/rewardshop-cms2/hero-slider/erlebe-das-beste-aus-10-jahren-iqos/',
        slug: 'erlebe-das-beste-aus-10-jahren-iqos',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-09-04T10:22:01.632512+02:00',
        alias_of: null,
        parent: {
          id: 142,
          meta: {
            type: 'rewardshop.HeroSlider',
            detail_url: 'https:///api/v2/drafts/142/',
            html_url: 'https://localhost/rewardshop-cms2/hero-slider/',
          },
          title: 'Hero Slider',
        },
        locale: 'en',
      },
      title: 'Erlebe das Beste aus 10 Jahren IQOS!',
      theme: 'light',
      headline:
        'Starte jetzt Deine faszinierende Zeitreise: Erlebe die gr\u00f6\u00dften IQOS Highlights und sammle insgesamt 150 IQOS Points.',
      content:
        '<p data-block-key="himsn">Starte jetzt Deine faszinierende Zeitreise: Erlebe die gr\u00f6\u00dften IQOS Highlights und sammle insgesamt 150 IQOS Points.</p>',
      cta_name: 'Los geht\'s',
      cta_link: 'https://de.iqosclub.com/history-of-iqos',
      image: {
        id: 577,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_Timeline-Desktop_1.png',
        title: 'IQOS_Timeline-Desktop (1)',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Desktop_1.scale-50.png',
          width: 1608,
          height: 564,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Desktop_1.scale-100.png',
          width: 3216,
          height: 1128,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Desktop_1.2e16d0ba.fill-4320x2316.png',
          width: 2106,
          height: 1128,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Desktop_1.2e16d0ba.fill-415x375.png',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Desktop_1.2e16d0ba.fill-830x750.png',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Desktop_1.2e16d0ba.fill-1245x1125.png',
          width: 1245,
          height: 1125,
        },
      },
      image_mobile: {
        id: 578,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_Timeline-Mobile_1.png',
        title: 'IQOS_Timeline-Mobile (1)',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Mobile_1.2e16d0ba.fill-1440x772.png',
          width: 1280,
          height: 688,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Mobile_1.2e16d0ba.fill-2880x1544.png',
          width: 1280,
          height: 688,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Mobile_1.2e16d0ba.fill-4320x2316_SB4ivVX.png',
          width: 1280,
          height: 688,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Mobile_1.scale-15.png',
          width: 192,
          height: 201,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Mobile_1.scale-25.png',
          width: 320,
          height: 336,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Timeline-Mobile_1.scale-35.png',
          width: 448,
          height: 470,
        },
      },
    },
    {
      id: 212,
      meta: {
        type: 'rewardshop.HeroSliderEntry',
        detail_url: 'https:///api/v2/drafts/212/',
        html_url:
          'https://localhost/rewardshop-cms2/hero-slider/die-allererste-terea-limited-pack-edition-ist-da/',
        slug: 'die-allererste-terea-limited-pack-edition-ist-da',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-08-28T17:26:16.640468+02:00',
        alias_of: null,
        parent: {
          id: 142,
          meta: {
            type: 'rewardshop.HeroSlider',
            detail_url: 'https:///api/v2/drafts/142/',
            html_url: 'https://localhost/rewardshop-cms2/hero-slider/',
          },
          title: 'Hero Slider',
        },
        locale: 'en',
      },
      title: 'Die allererste TEREA Limited Pack Edition ist da!',
      theme: 'light',
      headline:
        'Bunt, lebendig und vermutlich schnell vergriffen. Entdecke jetzt Deine Lieblingssorte im neuen Design \u2013 inspiriert von der IQOS Community.',
      content:
        '<p data-block-key="8svg9">Bunt, lebendig und vermutlich schnell vergriffen. Entdecke jetzt Deine Lieblingssorte im neuen Design \u2013 inspiriert von der IQOS Community.</p>',
      cta_name: 'Mehr erfahren',
      cta_link: 'https://de.iqosclub.com/10-years-limited-edition',
      image: {
        id: 571,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/header-rewardshop-art-box-slider-desktop.png',
        title: 'header-rewardshop-art-box-slider-desktop',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider-desktop.scale-50.png',
          width: 3216,
          height: 1128,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider-desktop.scale-100.png',
          width: 6432,
          height: 2256,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider.2e16d0ba.fill-4320x2316.png',
          width: 4210,
          height: 2256,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider-d.2e16d0ba.fill-415x375.png',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider-d.2e16d0ba.fill-830x750.png',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider.2e16d0ba.fill-1245x1125.png',
          width: 1245,
          height: 1125,
        },
      },
      image_mobile: {
        id: 572,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/header-rewardshop-art-box-slider-mobile.png',
        title: 'header-rewardshop-art-box-slider-mobile',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider-.2e16d0ba.fill-1440x772.png',
          width: 1440,
          height: 772,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider.2e16d0ba.fill-2880x1544.png',
          width: 2560,
          height: 1374,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider.2e16d0ba.fill-4320x2316_CYwbNbu.png',
          width: 2560,
          height: 1374,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider-mobile.scale-15.png',
          width: 384,
          height: 403,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider-mobile.scale-25.png',
          width: 640,
          height: 672,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/header-rewardshop-art-box-slider-mobile.scale-35.png',
          width: 896,
          height: 940,
        },
      },
    },
    {
      id: 152,
      meta: {
        type: 'rewardshop.HeroSliderEntry',
        detail_url: 'https:///api/v2/drafts/152/',
        html_url: 'https://localhost/rewardshop-cms2/hero-slider/neon-slider/',
        slug: 'neon-slider',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-05-08T11:56:32.132990+02:00',
        alias_of: null,
        parent: {
          id: 142,
          meta: {
            type: 'rewardshop.HeroSlider',
            detail_url: 'https:///api/v2/drafts/142/',
            html_url: 'https://localhost/rewardshop-cms2/hero-slider/',
          },
          title: 'Hero Slider',
        },
        locale: 'en',
      },
      title: 'IQOS ILUMA Neon Purple Limited Edition',
      theme: 'light',
      headline:
        'Bring Deinen Sommer zum Leuchten und sichere Dir ab 45\u20ac Deine Neon Purple Limited Edition.',
      content:
        '<p data-block-key="l8z1r">Bring Deinen Sommer zum Leuchten und sichere Dir ab 45\u20ac Deine Neon Purple Limited Edition.</p>',
      cta_name: 'Zur Limited Edition',
      cta_link:
        'https://www.iqos.com/de/de/iluma-neon-purple-limited-edition.html',
      image: {
        id: 372,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/IQOS_Illuma_neon.png',
        title: 'IQOS_Illuma_neon',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Illuma_neon.scale-50.png',
          width: 3216,
          height: 1128,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Illuma_neon.scale-100.png',
          width: 6432,
          height: 2256,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Illuma_neon.2e16d0ba.fill-4320x2316_mbdR9fL.png',
          width: 4210,
          height: 2256,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Illuma_neon.2e16d0ba.fill-415x375.png',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Illuma_neon.2e16d0ba.fill-830x750.png',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/IQOS_Illuma_neon.2e16d0ba.fill-1245x1125.png',
          width: 1245,
          height: 1125,
        },
      },
      image_mobile: {
        id: 371,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/rewarshop-slider-neon-purple-mobile.png',
        title: 'rewarshop-slider-neon-purple-mobile',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mobi.2e16d0ba.fill-1440x772.png',
          width: 1440,
          height: 772,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mob.2e16d0ba.fill-2880x1544.png',
          width: 2560,
          height: 1374,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mob.2e16d0ba.fill-4320x2316.png',
          width: 2560,
          height: 1374,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mobile.scale-15.png',
          width: 384,
          height: 403,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mobile.scale-25_OeNwxWX.png',
          width: 640,
          height: 672,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/rewarshop-slider-neon-purple-mobile.scale-35.png',
          width: 896,
          height: 940,
        },
      },
    },
    {
      id: 203,
      meta: {
        type: 'rewardshop.HeroSliderEntry',
        detail_url: 'https:///api/v2/drafts/203/',
        html_url:
          'https://localhost/rewardshop-cms2/hero-slider/iqos-cross-body-bag/',
        slug: 'iqos-cross-body-bag',
        show_in_menus: false,
        seo_title: '',
        search_description: '',
        first_published_at: '2024-07-24T12:11:52.478534+02:00',
        alias_of: null,
        parent: {
          id: 142,
          meta: {
            type: 'rewardshop.HeroSlider',
            detail_url: 'https:///api/v2/drafts/142/',
            html_url: 'https://localhost/rewardshop-cms2/hero-slider/',
          },
          title: 'Hero Slider',
        },
        locale: 'en',
      },
      title: 'Die limitierte IQOS Cross-Body Bag.',
      theme: 'light',
      headline:
        'Trag\u2019s mit Style. Die eigens f\u00fcr Dein IQOS designte Cross-Body Bag bietet Platz f\u00fcr Dein IQOS Device sowie Deine TEREA Sticks.',
      content:
        '<p data-block-key="skqn1">Trag\u2019s mit Style. Die eigens f\u00fcr Dein IQOS designte Cross-Body Bag bietet Platz f\u00fcr Dein IQOS Device sowie Deine TEREA Sticks.</p>',
      cta_name: 'Jetzt entdecken',
      cta_link: 'https://de.iqosclub.com/rewardshop',
      image: {
        id: 528,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/sliderImage2x.png',
        title: 'sliderImage@2x',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage2x.scale-50.png',
          width: 1608,
          height: 564,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage2x.scale-100.png',
          width: 3216,
          height: 1128,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage2x.2e16d0ba.fill-4320x2316.png',
          width: 2106,
          height: 1128,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage2x.2e16d0ba.fill-415x375.png',
          width: 415,
          height: 375,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage2x.2e16d0ba.fill-830x750.png',
          width: 830,
          height: 750,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage2x.2e16d0ba.fill-1245x1125.png',
          width: 1245,
          height: 1125,
        },
      },
      image_mobile: {
        id: 531,
        url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/original_images/sliderImage-mobilev2_big.jpg',
        title: 'sliderImage-mobilev2_big',
        image_desktop_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage-mobilev2_big.2e16d0ba.fill-1440x772.jpg',
          width: 1128,
          height: 606,
        },
        image_desktop_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage-mobilev2_big.2e16d0ba.fill-2880x1544.jpg',
          width: 1128,
          height: 606,
        },
        image_desktop_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage-mobilev2_big.2e16d0ba.fill-4320x2316.jpg',
          width: 1128,
          height: 606,
        },
        image_mobile_1x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage-mobilev2_big.scale-15.jpg',
          width: 169,
          height: 216,
        },
        image_mobile_2x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage-mobilev2_big.scale-25.jpg',
          width: 282,
          height: 360,
        },
        image_mobile_3x: {
          url: 'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/images/sliderImage-mobilev2_big.scale-35.jpg',
          width: 394,
          height: 503,
        },
      },
    },
  ],
};
