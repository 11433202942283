import normalizeUser from './user-normalizer';
import { DCE_LOGIN_URL } from '../constants';
import { getJwtFromCookies, deleteAuthCookies } from '../jwt';

export const initialState = () => ({
  // user is logged in if we have the information from gigya
  user: {
    jwt: null,
    uid: null,
    email: null,
  },
  authError: null,
});

export const getters = {
  authHeaders: ({ user = {} }) => {
    const jwt = getJwtFromCookies();
    return {
      headers: {
        'x-login-token': jwt,
      },
    };
  },
  userEmail: ({ user = {} }) => user?.email,
  isUserAuthenticated: ({ user = {} }) => {
    return user.jwt !== null;
  },
  isAuthProcessFinished: ({ authError, user }) =>
    Boolean(authError || user.jwt),
  authError: ({ authError }) => authError,
  userId: ({ user = {} }) => user?.uid,
};

export const actions = {
  async initAuth({ commit, dispatch }) {
    const jwt = getJwtFromCookies();
    if (jwt) {
      commit('SET_JWT_TOKEN', jwt);
    } else {
      setTimeout(() => {
        location.href = DCE_LOGIN_URL;
      }, 1000);
    }
  },
  refreshHeaders({ dispatch, getters, commit }) {
    const jwt = getJwtFromCookies();
    commit('SET_JWT_TOKEN', jwt);
    if (!jwt) {
      throw new Error('Could\'nt refresh the JWT.', error);
    }
    return getters.authHeaders;
  },
  async logout({ commit }) {
    const jwt = getJwtFromCookies();
    if (jwt) {
      // unset all user/customer related data
      commit('SET_JWT_TOKEN', null);
      commit('SET_USER', { uid: null, email: null });
      commit('consumerModule/LOGOUT', null, { root: true });
      deleteAuthCookies();
      setTimeout(() => {
        window.location = document.location.origin.includes('.stg.iqos.com')
          ? 'https://www.stg.iqos.com/de/de/home.html'
          : 'https://www.iqos.com/de/de/home.html';
      }, 500);
    }
  },
};

export const mutations = {
  SET_JWT_TOKEN(state, jwt) {
    state.user.jwt = jwt;
    // TODO: Restore this line
    // console.debug('SET_JWT_TOKEN', state.user.jwt);
  },
  SET_USER(state, { uid, email }) {
    state.user.uid = uid;
    state.user.email = email;
    console.debug('SET_USER', state.user);
  },
  SET_AUTH_ERROR(state, error) {
    state.authError = error;
    console.debug('SET_AUTH_ERROR', state.authError);
  },
  SET_AUTH_SERVICE_LOADED(state) {
    state.isAuthServiceLoaded = true;
    console.debug('SET_AUTH_SERVICE_LOADED', true);
  },
};

const authModule = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};

export default authModule;
