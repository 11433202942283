import { ResponsiveImageSet } from '@/types';

type ImageConfig =
  | string
  | {
      mobile: string;
      desktop: string;
    };

const getMobileAndDesktopUrls = (config: ImageConfig) =>
  typeof config === 'string' ? { mobile: config, desktop: config } : config;

export const getImageFromAssets = (config: ImageConfig): ResponsiveImageSet => {
  const { mobile, desktop } = getMobileAndDesktopUrls(config);
  const images = require.context('../assets/', true, /\.(png|jpg|svg)$/);
  const getComputedImagePath = (breakpointSrc: string, suffix = '') =>
    breakpointSrc.replace('.', `${suffix}.`).replace(/^/, './');
  return {
    mobile: {
      x1: images(getComputedImagePath(mobile)),
      x2: images(getComputedImagePath(mobile, '@2x')),
      x3: images(getComputedImagePath(mobile, '@3x')),
    },
    desktop: {
      x1: images(getComputedImagePath(desktop)),
      x2: images(getComputedImagePath(desktop, '@2x')),
      x3: images(getComputedImagePath(desktop, '@3x')),
    },
  };
};

const { VUE_APP_IMAGES_URL: staticServerURL } = process.env;
if (!staticServerURL) {
  throw new Error(
    'The VUE_APP_IMAGES_URL constant is not defined in the process.env'
  );
}

/**
 * getImageURL returns the absolute image url from the static server
 * @param {string} productId  product identifier
 * @param {string} imageId    image identifier
 * @returns {string} the constructed url in the following format:
 *   <staticServerURL>/products/<productId>/<imageId>.png
 */
export const getImageURL = (productId: string, imageId: string): string =>
  `${staticServerURL}/${productId}/${imageId}.png`;

export const ResponsiveImageFactory = (
  urlDesktop: string,
  urlMobile: string,
  alt = ''
): ResponsiveImageSet => ({
  alt,
  desktop: { x1: urlDesktop, x2: urlDesktop, x3: urlDesktop },
  mobile: { x1: urlMobile, x2: urlMobile, x3: urlMobile },
});

export const SimpleImageFactory = (url: string, alt = ''): ResponsiveImageSet =>
  ResponsiveImageFactory(url, url, alt);

export const generateResponsiveImageSetFromConfig = (config: {
  alt: string;
  srcBase: string;
  srcBaseMobile?: string;
  extension?: 'jpg' | 'png';
}): ResponsiveImageSet =>
  generateResponsiveImageSet(
    config.alt,
    config.srcBase,
    config.srcBaseMobile,
    config.extension || 'jpg'
  );

export const generateResponsiveImageSet = (
  alt: string,
  srcBase: string,
  srcBaseMobile?: string,
  extension: 'jpg' | 'png' = 'jpg'
): ResponsiveImageSet => ({
  alt,
  mobile: {
    x1: require(`@/assets${srcBaseMobile || srcBase}.${extension}`),
    x2: require(`@/assets${srcBaseMobile || srcBase}@2x.${extension}`),
    x3: require(`@/assets${srcBaseMobile || srcBase}@3x.${extension}`),
  },
  desktop: {
    x1: require(`@/assets${srcBase}.${extension}`),
    x2: require(`@/assets${srcBase}@2x.${extension}`),
    x3: require(`@/assets${srcBase}@3x.${extension}`),
  },
});
