import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import VueMq from 'vue3-mq';
import VueGtag, { trackRouter, useGtag } from 'vue-gtag-next';
import mapValues from 'lodash/mapValues';
import throttle from 'lodash/throttle';
import { createGtm } from '@gtm-support/vue-gtm';
import { createApp } from 'vue';
import { sync } from 'vuex-router-sync';
import Vue3TouchEvents from 'vue3-touch-events';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import authModule from '@/modules/auth/module';
import academyModule from '@/modules/academy/module';
import adventModule from '@/modules/advent/module';
// import advent2023Module from '@/modules/advent2023/module';
import advent2024Module from '@/modules/advent2024/module';
import consumerModule from '@/modules/consumer/module';
import earningModule from '@/modules/earning/module';
import shopModule from '@/modules/shop/module';
import podcastsModule from '@/modules/podcasts/module';
import voucherModule from '@/modules/voucher/module';
import storiesModule from '@/modules/stories/module';
import mtnModule from '@/modules/mtn/module';
import mtnFinaleModule from '@/modules/mtnfinale/module';
import maintenanceModule from '@/modules/maintenance/module';
import springRaffleModule from '@/modules/spring-raffle/module';
import newsTeaserModule from '@/modules/news-teaser/module';
import newsArticleRaffleModule from '@/modules/news-article-raffle/module';

import togetherXModule from '@/modules/together-x/module';

import App from './App.vue';
import router from './router/index.ts';
import store from './store/index.ts';
import { userGTMDatalayerGuard } from './routerGuards/router-guard';

import './assets/styles/styles.css';

import useTailwind from '@/use/useTailwind';

const tailwindTheme = useTailwind().theme;

// disable logs for production env
// @TODO: uncomment when we have the production env
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = console.debug = () => {};
}

main();

function main() {
  // modules instantiation
  authModule({ store, router });
  academyModule({ store, router });
  adventModule({ store, router });
  consumerModule({ store });
  shopModule({ store, router });
  earningModule({ router });
  podcastsModule({ store, router });
  mtnModule({ store, router });
  mtnFinaleModule({ store, router });
  voucherModule({ store, router });
  storiesModule({ store, router });
  springRaffleModule({ store, router });
  newsTeaserModule({ store, router });
  // advent2023Module({ store, router });
  advent2024Module({ store, router });
  togetherXModule({ store, router });
  newsArticleRaffleModule({ store, router });

  // Maintenance module below, to keep it visually
  // separated from the rest
  maintenanceModule(router);

  // Enable sync route state inside the store,
  // because consuming directly from imported router is
  // not ensured to have up to date information.
  sync(store, router);

  const oneYearInSeconds = 60 * 60 * 24 * 365;

  const getAnalyticsConfig = () => ({
    isEnabled: false,
    property: {
      id: process.env.VUE_APP_GA_ID,
      params: {
        anonymize_ip: true,
        cookie_expires: oneYearInSeconds,
      },
    },
  });
  trackRouter(router);

  const gtmConfig = {
    id: process.env.VUE_APP_GTM_ID_2
      ? [process.env.VUE_APP_GTM_ID_2, process.env.VUE_APP_GTM_ID]
      : process.env.VUE_APP_GTM_ID,
    debug: process.env.NODE_ENV !== 'production',
    vueRouter: router,
    defer: true,
    enabled: false, // It's enabled later on in src/routerGuards/router-guard.ts#userGTMDatalayerGuard
  };

  router.beforeResolve((to) => {
    const { set } = useGtag();
    const { gtagDomain } = to.meta;
    set({ dimension2: gtagDomain });
  });

  router.beforeEach(userGTMDatalayerGuard);

  const vueMqConfig = {
    breakpoints: {
      ...mapValues(tailwindTheme.screens, (breakpoint) => parseInt(breakpoint)),
    },
  };
  const vueApp = createApp(App);

  // Throttle warnings to avoid overload that ends up in app crashing some times
  vueApp.config.warnHandler = throttle(console.warn, 100);

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      app: vueApp,
      dsn: 'https://fa8b9114db29447499637c347f485b56@o44293.ingest.sentry.io/5949280',
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [
            'localhost',
            'frontend-main.stg.iqos.com', // Staging
            'club.pp.iqos.com', // Preprod
            'club.iqos.com', // Prod
            /^\//,
          ],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
    });
  }
  // Vue Gtag router auto-tracking

  vueApp.use(createGtm(gtmConfig));
  vueApp.use(router);
  vueApp.use(store);
  vueApp.use(VueMq, vueMqConfig);
  vueApp.use(VueGtag, getAnalyticsConfig(vueApp));
  vueApp.use(Vue3TouchEvents);
  vueApp.mount('#app');
}
