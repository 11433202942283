import { makeResponse } from '@/api/adapters';
import InfosResponse from './infos';
import {
  GetApplicationResponse,
  PostApplicationResponse,
  PostSupportArtistTeamResponse,
} from './application';
import GetApplicantsResponse from './applicants';
import archiveGridMock from './archiveGrid';
import {
  GetDocumentationHomepageContent,
  GetDocumentationPageContent,
  GetUpcomingEventsModuleContent,
  GetVotingHomepageContent,
  GetVotingPageContent,
} from './flexpages/togx';
import {
  GetDocumentationBluemchen,
  GetDocumentationMichaelSchulte,
  GetDocumentationKoolSavas,
} from './flexpages/togxArtists';
import { GetTokyoModuleContent } from './flexpages/togxTokyo';
import {
  GetDocumentationCarousel,
  GetDocumentationCarousel2,
  GetDocumentationCarousel3,
} from './documentation-carousel';
import {
  GetTokyoUgcFeed,
  GetPhotoUpload,
  GetPhotoUploadWithId,
  GetVideoUploadWithId,
  GetTokyoUgcFeedWithUploadFeed,
} from './tokyo-feed';
import { Get5YearsArticleContent } from './flexpages/5years';
import ErrorResponse from './error-response';

export default {
  setup: setupMocks,
};

// Setup the mocks defined with axios-mock-adapter
// Bear in mind that the guards are lazy so:
// if we have 2 urls matching, the first one will be the one returned.
function setupMocks(mock) {
  mock
    .onGet('togetherx/infos')
    .reply(makeResponse('togetherx/infos', InfosResponse));

  mock
    // .onGet('togetherx/application/get')
    // .reply(500, {
    //   custom_error: "Sorry, etwas ist schiefgegangen.",
    //   error_message: "Unauthorized user",
    //   error_type: "unknown",
    //   show_to_user: true,
    //   status: 500,
    // })
    .onGet('togetherx/application/get')
    .replyOnce(
      makeResponse(
        'Step Artist - GET togetherx/application',
        GetApplicationResponse
      )
    )
    .onGet('togetherx/application/get')
    .reply(
      makeResponse('Step Video - GET togetherx/application', {
        status: 200,
        application: {
          artist_team: 'kool_savas',
          terms_accepted: true,
        },
      })
    )
    .onGet('togetherx/application/get')
    .reply(
      makeResponse('Step Review - GET togetherx/application', {
        status: 200,
        application: {
          artist_team: 'kool_savas',
          terms_accepted: true,
          video: true,
          video_url:
            'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6614174da629c59f0be4d226_vid',
        },
      })
    )
    .onGet('togetherx/application/get')
    .reply(
      makeResponse('Step ThankYou - GET togetherx/application', {
        status: 200,
        application: {
          artist_team: 'kool_savas',
          terms_accepted: true,
          video: true,
          video_url:
            'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6614174da629c59f0be4d226_vid',
          is_submitted: true,
        },
      })
    );
  // .onGet('togetherx/application/get')
  // .replyOnce(
  //   makeResponse('Step 2 - GET togetherx/application', {
  //     status: 200,
  //     application: {
  //       artist_team: 'kool_savas',
  //       terms_accepted: true,
  //     },
  //   })
  // )
  // .onGet('togetherx/application/get')
  // .replyOnce(
  //   makeResponse('Step 3 - GET togetherx/application', {
  //     status: 200,
  //     application: {
  //       artist_team: 'kool_savas',
  //       terms_accepted: true,
  //       life_story: 'lorem ipsum dolor sit amet',
  //     },
  //   })
  // )
  // .onGet('togetherx/application/get')
  // .replyOnce(
  //   makeResponse('Step 4 - GET togetherx/application', {
  //     status: 200,
  //     application: {
  //       artist_team: 'kool_savas',
  //       terms_accepted: true,
  //       life_story: 'lorem ipsum dolor sit amet',
  //       voice_range: 'soprano',
  //     },
  //   })
  // )
  // .onGet('togetherx/application/get')
  // .replyOnce(
  //   makeResponse('Step 5 - GET togetherx/application', {
  //     status: 200,
  //     application: {
  //       artist_team: 'kool_savas',
  //       terms_accepted: true,
  //       life_story: 'lorem ipsum dolor sit amet',
  //       voice_range: 'soprano',
  //       photo: true,
  //       photo_url:
  //         'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6614174da629c59f0be4d226_photo',
  //     },
  //   })
  // )
  // .onGet('togetherx/application/get')
  // .replyOnce(
  //   makeResponse('Step 6 - GET togetherx/application', {
  //     status: 200,
  //     application: {
  //       artist_team: 'kool_savas',
  //       terms_accepted: true,
  //       life_story: 'lorem ipsum dolor sit amet',
  //       voice_range: 'soprano',
  //       photo: true,
  //       video: true,
  //       photo_url:
  //         'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6614174da629c59f0be4d226_photo',
  //       video_url:
  //         'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6614174da629c59f0be4d226_vid',
  //     },
  //   })
  // )
  // .onGet('togetherx/application/get')
  // .reply(
  //   makeResponse('Step 7 - GET togetherx/application', {
  //     status: 200,
  //     application: {
  //       artist_team: 'kool_savas',
  //       terms_accepted: true,
  //       life_story: 'lorem ipsum dolor sit amet',
  //       voice_range: 'soprano',
  //       photo: true,
  //       video: true,
  //       photo_url:
  //         'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6614174da629c59f0be4d226_photo',
  //       video_url:
  //         'https://pmg-dce-shop-cms-preprod-assets.s3.amazonaws.com/togetherx/6614174da629c59f0be4d226_vid',
  //       is_submitted: true,
  //     },
  //   })
  // );

  mock
    .onPost('togetherx/application')
    .reply(makeResponse('POST togetherx/application', PostApplicationResponse));
  mock
    .onPost('togetherx/support_artist_team')
    .reply(
      makeResponse(
        'POST togetherx/support_artist_team',
        PostSupportArtistTeamResponse
      )
    );

  mock
    .onGet('togetherx/application/get_all_applicants')
    .reply(
      makeResponse(
        'GET togetherx/application/get_applicants',
        GetApplicantsResponse
      )
    );

  mock
    .onGet('togetherx/voting')
    .replyOnce(
      makeResponse('GET togetherx/voting', {
        team_picked: 'kool_savas',
        my_votes: [],
        current_day_votes: [],
        points: 0,
        votes_left: 3,
        day_of_voting: 1,
        streak: false,
      })
    )
    .onGet('togetherx/voting')
    .reply(
      makeResponse('GET togetherx/voting', {
        team_picked: 'kool_savas',
        my_votes: [
          {
            name: 'Nazanin Pohlschmidt',
            applicant_id: '6617d057c04a58009e8132d4',
            vote_time: '2024-04-17',
          },
          {
            name: 'Nazanin Pohlschmidt',
            applicant_id: '6617d057c04a58009e8132d4',
            vote_time: '2024-04-18',
          },
          {
            name: 'Nina Steiner',
            applicant_id: '6618d4024f68597d2b812232',
            vote_time: '2024-04-19',
          },
        ],
        current_day_votes: [
          {
            name: 'Nina Steiner',
            applicant_id: '6618d4024f68597d2b812232',
            vote_time: '2024-04-19',
          },
        ],
        points: 10,
        day_of_voting: 1,
        points_total: 60,
        points_for_vote: 10,
        votes_left: 2,
        streak: false,
      })
    )
    .onGet('togetherx/voting')
    .reply(
      makeResponse('GET togetherx/voting', {
        team_picked: 'kool_savas',
        my_votes: [
          {
            name: 'Nazanin Pohlschmidt',
            applicant_id: '6617d057c04a58009e8132d4',
            vote_time: '2024-04-17',
          },
          {
            name: 'Nazanin Pohlschmidt',
            applicant_id: '6617d057c04a58009e8132d4',
            vote_time: '2024-04-18',
          },
          {
            name: 'Nina Steiner',
            applicant_id: '6618d4024f68597d2b812232',
            vote_time: '2024-04-19',
          },
        ],
        current_day_votes: [
          {
            name: 'Nina Steiner',
            applicant_id: '6618d4024f68597d2b812232',
            vote_time: '2024-04-19',
          },
          {
            name: 'Nazanin Pohlschmidt',
            applicant_id: '6617d057c04a58009e8132d4',
            vote_time: '2024-04-18',
          },
          {
            name: 'Yvonne Vogel-schienbein',
            applicant_id: '661f9aec81335a7c333baed0',
            vote_time: '2024-04-18',
          },
        ],
        points: 10,
        day_of_voting: 1,
        points_total: 60,
        points_for_vote: 10,
        votes_left: 0,
        streak: false,
      })
    );

  mock.onPost('togetherx/voting').reply(
    makeResponse('POST togetherx/voting', {
      status: 200,
    })
  );

  mock.onGet('togetherx/documentation/selections').reply(
    makeResponse('GET togetherx/selection', {
      selection: [],
    })
  );

  mock.onGet('togetherx/documentation/archive').reply(
    makeResponse('GET togetherx/documentation/archive', {
      selection: archiveGridMock,
    })
  );

  mock
    .onPost(/togetherx\/documentation\/selections\/.*\/.*/)
    // .networkError();
    .reply(
      makeResponse('POST togetherx/selection', {
        status: 200,
      })
    );

  mock
    .onGet('cms/flexpages/togx-voting-home-page')
    .reply(
      makeResponse(
        'GET cms/flexpages/togx-voting-home-page',
        GetVotingHomepageContent
      )
    );

  mock
    .onGet('cms/flexpages/togx-voting-page')
    .reply(
      makeResponse('GET cms/flexpages/togx-voting-page', GetVotingPageContent)
    );

  mock.onPost('togetherx/documentation').reply(
    makeResponse('POST togetherx/documentation', {
      status: 200,
    })
  );

  mock
    .onGet('cms/flexpages/togx-documentation-home-page')
    .reply(
      makeResponse(
        'GET cms/flexpages/togx-documentation-home-page',
        GetDocumentationHomepageContent
      )
    );

  mock
    .onGet('cms/flexpages/togx-documentation-page')
    .reply(
      makeResponse(
        'GET cms/flexpages/togx-documentation-page',
        GetDocumentationPageContent
      )
    );

  mock
    .onGet('cms/flexpages/togx-module-upcoming-events')
    .reply(
      makeResponse(
        'GET cms/flexpages/togx-module-upcoming-events',
        GetUpcomingEventsModuleContent
      )
    );

  mock
    .onGet('cms/flexpages/togx-tokyo-ugc-module')
    .reply(
      makeResponse(
        'GET cms/flexpages/togx-tokyo-ugc-module',
        GetTokyoModuleContent
      )
    );

  mock
    .onGet('togetherx/documentation')
    .replyOnce(
      makeResponse('GET togetherx/documentation', GetDocumentationCarousel)
    )
    .onGet('togetherx/documentation')
    .replyOnce(
      makeResponse('GET togetherx/documentation', GetDocumentationCarousel2)
    )
    .onGet('togetherx/documentation')
    .replyOnce(
      makeResponse('GET togetherx/documentation', GetDocumentationCarousel3)
    );

  mock
    .onGet('cms/flexpages/togx-documentation-kool-savas')
    .reply(
      makeResponse(
        'GET cms/flexpages/togx-documentation-kool-savas',
        GetDocumentationKoolSavas
      )
    );
  mock
    .onGet('cms/flexpages/togx-documentation-michael-schulte')
    .reply(
      makeResponse(
        'GET cms/flexpages/togx-documentation-michael-schulte',
        GetDocumentationMichaelSchulte
      )
    );
  mock
    .onGet('cms/flexpages/togx-documentation-bluemchen')
    .reply(
      makeResponse(
        'GET cms/flexpages/togx-documentation-bluemchen',
        GetDocumentationBluemchen
      )
    );
  mock
    .onGet('cms/flexpages/5-years-iqos-club')
    .reply(
      makeResponse(
        'GET cms/flexpages/5-years-iqos-club',
        Get5YearsArticleContent
      )
    );

  mock
    .onGet('togetherx/application/get_selected_applicants/bluemchen')
    .reply(
      makeResponse(
        'GET togetherx/application/get_applicants',
        GetApplicantsResponse
      )
    );

  mock.onPost('action/TogetherXMusic_LuftundLiebe_Gewinnspiel').reply(
    makeResponse('POST action/TogetherXMusic_LuftundLiebe_Gewinnspiel', {
      status: 200,
    })
  );

  mock
    .onGet('action/TogetherXMusic_LuftundLiebe_Gewinnspiel')
    .replyOnce(
      makeResponse('GET action/TogetherXMusic_LuftundLiebe_Gewinnspiel', {
        action_done: false,
        success_button_text: '',
        button_text: '',
      })
    )
    .onGet('action/TogetherXMusic_LuftundLiebe_Gewinnspiel')
    .reply(
      makeResponse('GET action/TogetherXMusic_LuftundLiebe_Gewinnspiel', {
        action_done: true,
        success_button_text: '',
        button_text: '',
      })
    );

  mock
    .onPost(/togetherx\/documentation\/stories\/.*\/.*/, {
      progress_percent: 90,
    })
    .reply(
      makeResponse('POST /togetherx/documentation/stories 90%', {
        message: 'success',
        given_points: 25,
        only_statuspoints: true,
        only_points: false,
        success_button_text: '',
      })
    )
    .onPost(/togetherx\/documentation\/stories\/.*\/.*/, {
      progress_percent: 100,
    })
    .reply(
      makeResponse('POST /togetherx/documentation/stories 100%', {
        message: 'success',
        given_points: 25,
        only_statuspoints: true,
        only_points: false,
        success_button_text: '',
      })
    );

  mock
    .onGet(/togx-tokio-ugc\/feed/)
    .replyOnce(
      makeResponse('GET togx-tokio-ugc/feed first_call', GetTokyoUgcFeed)
    )
    .onGet(/togx-tokio-ugc\/feed/)
    .reply(
      makeResponse(
        'GET togx-tokio-ugc/feed second_or_later_call',
        GetTokyoUgcFeedWithUploadFeed
      )
    );

  mock
    .onGet(/togx-tokio-ugc\/upload\/photo$/)
    .reply(makeResponse('GET togx-tokio-ugc/upload/photo', GetPhotoUpload));

  mock
    .onGet(/togx-tokio-ugc\/upload\/photo\/.*/)
    .reply(
      makeResponse('GET togx-tokio-ugc/upload/photo/:ID', GetPhotoUploadWithId)
    );

  mock
    .onGet(/togx-tokio-ugc\/upload\/video\/.*/)
    .reply(
      makeResponse('GET togx-tokio-ugc/upload/video/:ID', GetVideoUploadWithId)
    );
}
