import { parseCookies } from '@/utils/cookies';

const { hostname } = document.location;
const slicePosition = hostname.indexOf('de.') + 2;
export const COOKIE_PARENT_DOMAIN = hostname?.slice(slicePosition) || '.iqos.com';
export const AUTH_JWT_COOKIE_NAME = 'gig_jwt';
export const AUTH_UID_COOKIE_NAME = 'gig_uid';

export const getJwtFromCookies = (): string => {
  const cookiesObject = parseCookies();
    const gigJwt = cookiesObject?.[AUTH_JWT_COOKIE_NAME];
    return process.env.NODE_ENV === 'production'
      ? gigJwt
      : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlJFUTBNVVE1TjBOQ1JUSkVNemszTTBVMVJrTkRRMFUwUTBNMVJFRkJSamhETWpkRU5VRkJRZyJ9.eyJpc3MiOiJodHRwczovL2ZpZG0uZ2lneWEuY29tL2p3dC8zXzBrR1IwV09wbWM2eHZZdEdVZzl6M1JGNTZNRDZicGVMUVJHM1NGczBCUVdxSHIxekF5bFcxREFkZ0J1Tm9ySGkvIiwiYXBpS2V5IjoiM18wa0dSMFdPcG1jNnh2WXRHVWc5ejNSRjU2TUQ2YnBlTFFSRzNTRnMwQlFXcUhyMXpBeWxXMURBZGdCdU5vckhpIiwiaWF0IjoxNzI3NzA5MzI0LCJleHAiOjE3Mjc3MTI5MjQsInJpc2tTY29yZSI6MC4zLCJzdWIiOiI0NTk1OWNiMTY0MTk0YTcwOTgyOGVhZWYzNDNlODRmNyJ9.qUfzdGoJS9yTM5pfy8NoQ0Qh81UYvwjKjF7nnUnrLPNnfZil9razhpI2UmIfXPSw8oMqKbQ82PnWiXGbnOTAzH4e_JmZN0KiDcasdT3IpU9ouhbwKAY6b77jl51_dDRuGBv-J1Bj6FR-0xyOi9POpxfXl6VnLq5qGbmuoNvM8HE6nPSOomjWDrYSzQYZScCeEm0IuSIm2FYw0OB4EQ7ThnYc68uW2FsCttMi_CgfuvYrKcZKYkpK51Ou9XVUb5pW2IP0T56mI_2N1H60v-5aw10qICQY6SuxfovuP_scLSnToE7E3YbdkU3gxvaZ1Pl_f60j40YEs6VauLHfS3170Q';
}

export const getUserIdFromCookies = (): string => {
  const cookiesObject = parseCookies();
    const gigUid = cookiesObject?.[AUTH_UID_COOKIE_NAME];
    return process.env.NODE_ENV === 'production'
      ? gigUid
      : 'NDU5NTljYjE2NDE5NGE3MDk4MjhlYWVmMzQzZTg0Zjc=';
}

export const deleteAuthCookies = (): void => {
  document.cookie = `${AUTH_JWT_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${COOKIE_PARENT_DOMAIN};`;
  document.cookie = `${AUTH_UID_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${COOKIE_PARENT_DOMAIN};`;
}