// keep the mocks as close as possible to the real returned response

const jsonResponse = `
{
  "status": 200,
  "consumer_id": "0efdac0ef27841d29f26bdd632f0d438",
  "consumer": {
    "firstName": "Gottlieb",
    "lastName": "Salzstreuer",
    "dateOfBirth": "1965-05-05",
    "gender": "m",
    "email": "testgerman87@gmail.com",
    "technicalEmail": false,
    "country": "DE",
    "preferredLanguage": "en",
    "mobile": [
      {
        "countryCode": "+49",
        "number": "1786767890"
      }
    ],
    "emailVerified": true
  },
  "loyalty": {
    "points": 2591,
    "status_points": 240,
    "status_level": "Silver"
  }
}
`;

export default JSON.parse(jsonResponse);
