export const createOneTrustCookieScript = (
  id: string,
  document: Document
): void => {
  const script = document.createElement('script');
  script.setAttribute(
    'src',
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
  );
  script.setAttribute('charset', 'UTF-8');
  script.setAttribute('data-domain-script', id);
  script.setAttribute('data-document-language', 'true');
  script.defer = true;
  document.head.prepend(script);
};

export const createOneTrustCookieScriptConsent = (
  id: string,
  document: Document
): void => {
  const script = document.createElement('script');
  script.setAttribute(
    'src',
    `https://cdn.cookielaw.org/consent/${id}/OtAutoBlock.js`
  );
  script.type = 'text/javascript';
  document.head.prepend(script);
};

type CookiesObject = { [key: string]: string };

export const parseCookies = (): CookiesObject => {
  const splitCookies = document.cookie.split(';');
  const cookiesObject = splitCookies.reduce((acc, cookie): CookiesObject => {
    const splitKeys = cookie.split('=');
    const normalizeKeys = splitKeys[0].replace('"', '_').trim();
    return (acc = {
      ...acc,
      [normalizeKeys]: splitKeys[1],
    });
  }, {});
  return cookiesObject;
};
