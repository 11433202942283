import useSWRV from 'swrv';
import { computed, watchEffect, type ComputedRef, type Ref } from 'vue';
import { useAuth } from '@/use/useAuth';
import { useRouter } from 'vue-router';
import { routeNames } from '@/router/index';

import { getFeatures } from '@/api/features';

type ToggleVariant = {
  name: string;
  enabled: boolean;
  payload: { type: string; value: string };
};

type Toggle = {
  name: string;
  enabled: boolean | null;
  variant?: ToggleVariant;
};

type ProtectedRouteData = {
  isToggleEnabled: ComputedRef<boolean>;
  isLoading: Ref<boolean>;
  toggleVariant?: ComputedRef<ToggleVariant>;
};

type UseFeaturesComposable = {
  toggleList: ComputedRef<Toggle[]>;
  getFeature: (name: string) => Toggle;
  isLoading: Ref<boolean>;
};

const defaultToggles = [];

export const useFeatures = (): UseFeaturesComposable => {
  const { consumer } = useAuth();

  const { data, isValidating } = useSWRV(
    () => consumer.value?.email && 'feature-toggles',
    () => getFeatures({
      params: {
        userId: consumer.value.email.toLowerCase(),
      },
    }),
    { revalidateOnFocus: false }
  );

  const normalisedToggles = computed(() => [
    ...defaultToggles,
    ...(data.value?.data?.toggles || []),
  ]);

  /**
   * @TODO: Handle errors on Unleash API
   */

  const getFeature = (name: string) =>
    normalisedToggles.value.find((x) => x.name === name) || {
      name,
      enabled: null, // Return null if not exists
    };

  return {
    getFeature,
    isLoading: isValidating,
    toggleList: normalisedToggles,
  };
};

export const useProtectedRoute = (toggleName: string): ProtectedRouteData => {
  const router = useRouter();

  const { getFeature, isLoading } = useFeatures();
  const toggle = computed(() => getFeature(toggleName));
  const isToggleEnabled = computed(() => toggle.value.enabled);
  const toggleVariant = computed(() => toggle.value.variant);

  watchEffect(() => {
    if (!isLoading.value && !isToggleEnabled.value) {
      router.push({ name: routeNames.NOT_FOUND });
    }
  });

  return { isToggleEnabled, isLoading, toggleVariant };
};
