import { computed, ComputedRef } from 'vue';
import { type Consumer } from '@/modules/consumer/types';
import { useStore } from 'vuex';
import { getJwtFromCookies } from '@/modules/auth/jwt';

type User = { email: string, jwt: string, uid: string }

export const useAuth = (): {
  headers: ComputedRef<unknown>,
  user: ComputedRef<{ jwt: string, uid: string | null, email: string | null }>,
  consumer: ComputedRef<Consumer>,
} => {
  const store = useStore();
  store.dispatch('authModule/refreshHeaders', null, { root: true,});

  const headers = computed(() => store.getters['authModule/authHeaders']);
  const consumer = computed<Consumer>(() => store.getters['consumerModule/consumer']);

  const user = computed<User>(() => ({
    email: consumer.value.email,
    jwt: getJwtFromCookies(),
    uid: consumer.value.id,
  }));

  return {
    headers,
    user,
    consumer,
  };
};
