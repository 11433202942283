import { RouteRecordRaw, Router } from 'vue-router';

const DEFAULT_GTAG_DOMAIN = 'advent2024';

export const routeAdventBreadcrumbsMeta = {
  ADVENT_LANDING: { name: 'Advent2024', link: '/adventskalender2024' },
};

export const routeNames = {
  ADVENT_ROOT: 'advent2024-root',
};

const routes: RouteRecordRaw[] = [
  {
    path: '/adventskalender2024',
    name: routeNames.ADVENT_ROOT,
    component: () =>
      import(/* webpackChunkName: "Advent2024" */ './views/AdventRoot.vue'),
    meta: {
      gtagDomain: DEFAULT_GTAG_DOMAIN,
      routeBreadcrumbName: 'Advent',
    },
  },
];

function initialiseAdventRouter(router: Router): void {
  routes.forEach(router.addRoute);
}

export default initialiseAdventRouter;
