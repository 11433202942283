import { type Router } from 'vue-router';

import initialiseAdventRouter from './router';

function advent2024Module({
  router,
}: {
  router: Router;
}): void {
  initialiseAdventRouter(router);
}

export default advent2024Module;
